import { onValue } from "firebase/database";
import { getUserProfileFromUsername } from "lib/profile/username";
import { getDjShowsRef } from "lib/show/refs";
import { Show, ShowMetadata } from "lib/show/types";
import { useEffect, useState } from "react";


/**
 * A react hook that tracks the DJ's current show for changes to its metadata,
 * or listens for when a show is created
 */
export const useShowMetadata = (username: string): ShowMetadata => {
    const [showId, setActiveShowId] = useState<string>();
    const [dj, setDj] = useState<string>();
    const [title, setTitle] = useState<string>();
    const [isReady, setIsReady] = useState(false);

    // Subscribe to the DJ's shows in firebase
    useEffect(() => {
        // First, retrieve the DJ's profile (incl. their user id)
        getUserProfileFromUsername(username).then(profile => {
            if (!profile) {
                return;
            }
            setDj(profile.uid);
            onValue(getDjShowsRef(profile.uid), snap => {
                if (!snap.exists()) {
                    setIsReady(true);
                    return;
                }
                const djShows = snap.val() as Record<string, Show>;
                const activeShow = Object.values(djShows).filter(s => s.status === 'active')[0];

                // If there's an active show & it's the current one, then swap to that one
                if (activeShow && activeShow.id !== showId) {
                    setActiveShowId(activeShow.id);
                    setTitle(activeShow.title);
                // If there's no active show, but the state says there is - then it's been deactivated
                // so let's remove it from this components state
                } else if (!activeShow && showId) {
                    setActiveShowId(undefined);
                    setTitle(undefined);
                }

                if (!isReady) {
                    setIsReady(true);
                }
            });
        });
    }, [])

    return {isReady, showId, dj, title};
}