// Import the functions you need from the SDKs you need
import { debug } from "console";
import { initializeApp } from "firebase/app";
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';
import { isDev } from "lib/env";

const databasePrefix = () => {
  if (isDev()) {
    return 'dev-'
  } else {
    return '';
  }
}

const firebaseConfig = {
  apiKey: "AIzaSyA_DX1bFWXu8XrDE0kjTNoyn85-jcrs4jM",
  authDomain: "magnify-software.firebaseapp.com",
  databaseURL: `https://${databasePrefix()}mxsw-youdj.asia-southeast1.firebasedatabase.app/`,
  projectId: "magnify-software",
  storageBucket: "magnify-software.appspot.com",
  messagingSenderId: "265245671095",
  appId: "1:265245671095:web:39dd5dce3f34da3ee9d732",
  measurementId: "G-7R22CQWL4B"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider('6LcAfjUgAAAAADNjnqnqsiUh1yYw6ijyZnU1qr_n')
})