import { Song } from 'components/song';
import { Box, Button, Spinner, Text } from 'grommet';
import React, { useState } from 'react';
import * as Icons from 'grommet-icons';
import { Song as SongType } from 'api/youdj/requests';
import { requestSongForShow } from 'lib/show/requests';
import { useActiveShow } from 'lib/show/context';
import { useAuth } from 'hooks/auth';

interface SearchResultRowProps {
    song: SongType;
}
export const SearchResultRow: React.FC<SearchResultRowProps> = ({song}) => {

    const [isRequesting, setIsRequesting] = useState(false);
    const [hasRequested, setHasRequested] = useState(false);
    const [error, setError] = useState('');
    const {showId} = useActiveShow();
    const {user} = useAuth();

    const onSelect = () => {
        setIsRequesting(true);
        requestSongForShow(showId, song.id!, user!).then(({success}) => {
            setHasRequested(true);
        }).catch(err => {
            setError('Song has already been requested');
        }).finally(() => {
            setIsRequesting(false);
        })
    }

    return (
        <Box direction='row' margin='small' justify='between' width='100%' align='center'>
            <Song
                {...song}
            />
            <Button
                icon={
                    <>
                        {isRequesting && (
                            <Spinner />
                        )}
                        {hasRequested && !error && (
                            <Icons.Checkmark color='status-ok' />
                        )}
                        {error && (
                            <Text color='status-error'>{error}</Text>
                        )}
                        {!hasRequested && !isRequesting && !error && (
                            <Icons.AddCircle 
                                onClick={onSelect}
                            />
                        )}
                    </>
                }
            />
        </Box>
    )
}