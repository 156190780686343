import React, { useState } from 'react';

import {Box, Image, Heading, Card, CardHeader, CardBody, CardFooter, ResponsiveContext, Spinner} from 'grommet';
import { usePageSize } from 'hooks/ui';
import Logo from 'assets/inverse_logo.png';
import { Login } from 'pages/auth/login';
import { AuthBox } from 'components/auth_box';
import { useAuth } from 'hooks/auth';
import { InformLoggedIn } from 'pages/auth/logged_in';
import { LoginViewProps } from 'pages/auth/types';

export const AuthPage: React.FC = () => {

    const {determined, user, isAnonymous} = useAuth(); 
    const [showActiveAccountOverride, setOverride] = useState<boolean>();

    const showActiveAccount = showActiveAccountOverride !== undefined ? showActiveAccountOverride : !isAnonymous;
    const onSwitchView = () => {setOverride(!showActiveAccount)};

    const viewProps: LoginViewProps = {
        onSwitchView
    }

    return (
       <AuthBox>
           {!determined ? (
               <Spinner size='medium' />
           ) : (
            showActiveAccount ? (
                <InformLoggedIn {...viewProps} />
            ) : (
                <Login {...viewProps} />
            )
           )}
           
       </AuthBox>
    )
}