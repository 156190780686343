import { defaultProps, ThemeType } from "grommet";
import { palette } from "theme/colours";

const theme: ThemeType = {
    ...defaultProps.theme,
    global: {
        ...defaultProps.theme.global,
        colors: palette,
    }
}

export default theme;