import { LogoQRCode } from 'components/qr_code';
import { Box, Heading, Button } from 'grommet';
import { useParams } from 'hooks/navigation';
import { usePageSize } from 'hooks/ui';
import { RequestsList } from 'pages/show/requests';
import React from 'react';
import * as Icons from 'grommet-icons';

/**
 * LiveView contains a QR code for the current URL 
 * and shows the requests but doesn't provide any
 * interaction options (you can't request a song).
 */
interface Props {
    exitLiveMode: () => void;
}
export const LiveView: React.FC<Props> = ({exitLiveMode}) => {   

    const screenSize = usePageSize();

    return (
        <Box direction={screenSize === 'small' ? 'column' : 'row'} width='100%' justify='around'>
            <Box direction='column' justify='start' pad='xlarge'>
                <LogoQRCode 
                    data={window.location.href}
                />
                <Button 
                    label='Request Mode'
                    onClick={exitLiveMode}
                    icon={
                        <Icons.Search />
                    }
                />
            </Box>
            <Box pad='xlarge'>
                <RequestsList top={6}/>
            </Box>
        </Box>
    )
}