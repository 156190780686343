import { Song as SongType } from 'api/youdj/requests';
import { Box, Image, Text } from 'grommet';
import React from 'react';

interface SongProps extends SongType {
    size?: 'small' | 'standard'
}
export const Song: React.FC<SongProps> = ({image_url, name, artist_name, size = 'standard'}) => {

    const width = size === 'standard' ? '60px' : '50px';
    return (
        <Box direction='row'>
            <Image 
                src={image_url}
                width={width}
                style={{
                    maxWidth: width,
                    maxHeight: width
                }}
                margin={{right: 'medium'}}
                />
                <Box>
                    <Text truncate>{name}</Text>
                    <Text weight={'lighter'} truncate>{artist_name}</Text>
                </Box>
        </Box>
    )
}