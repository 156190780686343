import { RequestStatus } from "lib/show/types";

export const ACTIVE_STATUS_OPTIONS: RequestStatus[] = [
    'queued', 'requested', 'playing_now'
]

export const getLiveStatusDescription = (status: RequestStatus) => {
    if (status === 'playing_now') {
        return 'Playing now'
    } else if (status === 'queued') {
        return 'Queued'
    }
}

export const getLiveStatusColor = (status: RequestStatus) => {
    if (status === 'playing_now') {
        return 'status-ok'
    } else if (status === 'queued') {
        return 'status-warning'
    }
}