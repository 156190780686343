import { AuthBox } from 'components/auth_box';
import { AuthSpinner } from 'components/auth_spinner';
import { GoogleAuthProvider } from 'firebase/auth';
import { Spinner, Text } from 'grommet';
import { useAuth } from 'hooks/auth';
import { useParams } from 'hooks/navigation';
import { loginFromRedirectResponse, loginWithProvider } from 'lib/auth/login';
import React from 'react';
import { useNavigate } from 'react-router';

const providerFromName = {
    google: GoogleAuthProvider
}

export const OAuthPage: React.FC = () => {
    const navigate = useNavigate();
    const { providerName } = useParams();
    const { loginType, update } = useAuth();

    const onError = () => {
        update('error', 'Authentication failed');
        navigate('/auth');
    }

    const onRedirectResult = React.useCallback(() => {
        if (!providerName) {
            onError();
            return;
        }
        loginFromRedirectResponse(providerName).then(user => {
            update('user', user);
            navigate('/account');
        }).catch(err => {
            onError();
            return;
        });
    }, []);


    React.useEffect(() => {
        if (!providerName) {
            onError();
            return;
        }
        
        // If loginType is defined, then we're in the process of 
        // redirecting to an oauth provider
        if (loginType === 'oauth') {
            loginWithProvider(providerName)
            return;
        }

        // If we're not trying to redirect to an oauth provider,  
        // we've likely been redirected after an oauth attempt
        onRedirectResult();
    }, [onRedirectResult]);

    return (
        <AuthSpinner text={providerName ? `Signing in with ${providerName}...` : 'Just a moment...'} />
    )
}