import { Song } from 'components/song';
import { Box } from 'grommet';
import { getLiveStatusColor } from 'lib/show/constants';
import { computeVoteScore } from 'lib/show/sorting';
import { SongRequest } from 'lib/show/types';
import { RequestTools } from 'pages/show/tools';
import React from 'react';

interface RequestProps extends SongRequest {
    sortingRef?: React.ForwardedRef<HTMLDivElement>;
}
export const Request: React.FC<RequestProps> = ({song, votes, id, sortingRef, status}) => {
    const requestScore = computeVoteScore(votes);
    const scoreColor = requestScore === 0 ? '0 0 0' : (
        requestScore > 0 ? '0 199 129' : '255 64 64'
    )
    const borderColor = getLiveStatusColor(status);

    return (
        <Box 
            elevation='medium'  
            margin={{vertical: 'small'}}
            pad='medium' 
            round='medium'
            direction='row'
            justify='between'
            key={id}
            style={requestScore !== 0 ? ({
                boxShadow: `0px 4px 8px rgb(${scoreColor} / 40%)`
            }) : undefined}
            width='100%'
            ref={sortingRef}
            border={borderColor ? {color: borderColor} : undefined}
            >
            <Song
                {...song}
            />
            <RequestTools 
                id={id}
                votes={votes}
                score={requestScore}
                status={status}
            />
        </Box>
    )
}

export const RequestWithRef: React.FC<SongRequest> = React.forwardRef((props, ref) => (
    <Request {...props} sortingRef={ref as React.ForwardedRef<HTMLDivElement>} /> 
))