import { Box, Button, Text } from 'grommet';
import { useAuth } from 'hooks/auth';
import React from 'react';
import { Link } from 'react-router-dom';

interface Props {
    onEditUsername: () => void;
}
export const CompleteAccountView: React.FC = () => {
    const {username} = useAuth();

    return (
        <>
            <Box direction='row'>
                <Text>@{username}</Text>
            </Box>
            <Box height='15px' />
            <Link to={`/dj/${username}`}>
                <Button label='View Profile' />
            </Link>
            <Box height='15px' />
            <Button label='Edit Account' />
        </>
    )
}