import { Avatar, Box, Heading, Spinner, Text } from 'grommet';
import * as Icons from 'grommet-icons';
import { useAuth } from 'hooks/auth';
import { useParams } from 'hooks/navigation';
import { usePageSize } from 'hooks/ui';
import { UserProfile } from 'lib/profile/types';
import { getUserProfileFromUsername } from 'lib/profile/username';
import { Header } from 'pages/profile/header';
import { ProfileNotFound } from 'pages/profile/not_found';
import { ShowController } from 'pages/show';
import React from 'react';
import { Navigate } from 'react-router';



export const ProfilePage: React.FC = () => {

    const {username} = useParams();
    const [hasLoaded, setHasLoaded] = React.useState(false);
    const [profile, setProfile] = React.useState<UserProfile>();
    const [showHeader, setShowHeader] = React.useState(true);
    const {determined} = useAuth();
    const pageSize = usePageSize();

    const toggleProfileHeader = (show: boolean) => {
        setShowHeader(show);
    }

    React.useEffect(() => {
        if (profile || !username) {
            return;
        }
        getUserProfileFromUsername(username).then(p => {
            setProfile(p);
        })
        .catch(err => {
            console.error(err);
        }).finally(() => {
            setHasLoaded(true);
        });
    }, []);

    if (!username) {
        return <Navigate to='/' />
    }

    if (!hasLoaded) {
        return <Spinner size='medium'/>
    }

    if (!profile) {
        return <ProfileNotFound username={username}/>
    }

    const width = pageSize === 'small' ? '100%' : '70%'

    return (
        <Box  
        align='center'
        direction='column'
        width='100%'
        pad='small'
        >
        <Box
            justify='around'
            direction='column'
            align='center'
            width='100%'
            >
            {showHeader && <Header {...profile} />}
            {profile && (
                <Box justify='center' align='center' width={width}>
                    {!determined ? (
                        <Spinner size='small'/>
                    ) : (
                        <ShowController toggleProfileHeader={toggleProfileHeader} />
                    )}
                </Box>
            )}
        </Box>
    </Box>
    )
}