import { Box, Heading, Button } from 'grommet';
import { useActiveShow, useSongRequests } from 'lib/show/context';
import React, { useState } from 'react';
import FlipMove from 'react-flip-move';

import { LiveView } from 'pages/show/live';
import { RequestWithRef } from 'pages/show/request';
import { SongSearch } from 'pages/show/search';
import * as Icons from 'grommet-icons';
import { RequestsList } from 'pages/show/requests';

export const ActiveShow: React.FC = () => {

    const {title, toggleLiveMode, liveMode} = useActiveShow()
    const requests = useSongRequests();

    if (!requests) {
        return <></>
    }

    if (liveMode) {
        return <LiveView exitLiveMode={toggleLiveMode}/>
    }
            
    return (
        <Box width='100%'>
            <Box align='center' pad='small'>
                <Heading level='3' style={{fontWeight: '500', width: '100%'}} textAlign='center'>
                    {title}
                </Heading>
                <Button 
                    label='Live Mode' 
                    onClick={toggleLiveMode}
                    icon={
                        <Icons.CirclePlay />
                    }    
                />
            </Box>
            <SongSearch />
            <Heading level='3' style={{fontWeight: '400', width: '100%'}} margin={{left: 'small'}}>
                Requests
            </Heading>
            <RequestsList />
        </Box>
    )   
}