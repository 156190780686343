import { User } from "firebase/auth";
import { UserProfile, ProfileAttribute } from "lib/profile/types";
import {getDatabase, ref, get, update} from 'firebase/database';

const profileRef = (user: User, attr?: ProfileAttribute) => {
    let path = `profiles/${user.uid}/`;
    if (attr) {
        path += attr;
    }

    return ref(getDatabase(), path);
}

export function getProfile(user: User): Promise<UserProfile | undefined>;
export function getProfile<A extends ProfileAttribute>(user: User, attr: A): Promise<UserProfile[A] | undefined>;
export async function getProfile(user: User, attr?: ProfileAttribute) {
    const accountRef = profileRef(user, attr);
    const snap = await get(accountRef);
    if (!snap.exists()) {
        return undefined;
    }

    return snap.val();
}

export const updateProfile = async <K extends ProfileAttribute>(user: User, attr: K, val: UserProfile[K]) => {
    const userRef = profileRef(user);
    await update(userRef, {
        [attr]: val
    });
}