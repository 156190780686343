import { SongRequest, Votes } from "lib/show/types";

export const computeVoteScore = (votes: Votes) => {
    return Object.values(votes || {}).reduce((acc, a) => acc + a, 0);
}

export const sortRequestsByVote = (requests: SongRequest[]) => {
    return requests.sort((a, b) => {
        const scoreA = computeVoteScore(a.votes);
        const scoreB = computeVoteScore(b.votes);

        if (scoreA > scoreB) {
            return -1;
        } else if (scoreA === scoreB) {
            return 0;
        } else {
            return 1;
        }
    });
}