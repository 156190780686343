import React, { useEffect, useRef, useState } from 'react';
import { ActiveShowContext, RequestDict, ShowContext as ContextType, ShowMetadata, SongRequest, Votes } from 'lib/show/types';
import { sortRequestsByVote } from 'lib/show/sorting';
import { User } from 'firebase/auth';
import { getDatabase, onValue, ref } from 'firebase/database';
import { ACTIVE_STATUS_OPTIONS } from 'lib/show/constants';

export const ShowContext = React.createContext<ContextType>({
    isOwner: false,
    liveMode: false,
    toggleLiveMode: () => {}
})

export const useShowContext = () => React.useContext(ShowContext);

/**
 * Returns the ShowContext but with a strictly defined show.
 */
export const useActiveShow = (): ActiveShowContext => {
    return useShowContext() as ActiveShowContext;
}

const getRequestsRef = ({showId, dj}: ActiveShowContext) => {
    return ref(getDatabase(), `shows/${dj}/${showId}/requests`);
}

const isActiveRequest = ({status}: SongRequest) => {
    return ACTIVE_STATUS_OPTIONS.indexOf(status) !== -1
}


/**
 * Hook that returns the sorted song requests
 */
export const useSongRequests = (): SongRequest[] => {

    const show = useActiveShow();
    const requestsRef = useRef(getRequestsRef(show))
    const [sortedRequests, setSortedRequests] = useState<SongRequest[]>([]);

    // Subscribe to the shows requests 
    useEffect(() => {
        onValue(requestsRef.current, snap => {
            if (!snap.exists()) {
                return;
            }
            const currentRequests = snap.val() as RequestDict;
            const filteredRequests = Object.values(currentRequests).filter(isActiveRequest);
            setSortedRequests(sortRequestsByVote(filteredRequests));
        });
    }, [])

    return sortedRequests;
}