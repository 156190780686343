import { useAuth } from 'hooks/auth';
import { useActiveShow } from 'lib/show/context';
import React from 'react';
import {Menu, Text} from 'grommet';
import * as Icons from 'grommet-icons';
import { Score } from 'pages/show/score';
import { RequestStatus } from 'lib/show/types';
import { markRequestAsStatus } from 'lib/show/requests';
import { ToolsProps } from 'pages/show/tools/types';
import { getUsersVote, voteForRequest } from 'lib/show/vote';
import { Voting } from 'pages/show/tools/voting';
import { ACTIVE_STATUS_OPTIONS, getLiveStatusColor, getLiveStatusDescription } from 'lib/show/constants';

export const FanTools: React.FC<ToolsProps> = ({id, score, votes, status}) => {
    const {isOwner, liveMode, showId, dj} = useActiveShow();
    const {user} = useAuth();
    const existingUserVote = getUsersVote(votes, user!);

    const onVote = (vote: -1 | 1) => {
        const targetVote = existingUserVote === vote ? 0 : vote;
        return voteForRequest(user!, id, showId, dj, targetVote);
    }

    const statusDescription = getLiveStatusDescription(status);
    if (statusDescription) {
        return (
            <Text color={getLiveStatusColor(status)}>
                {statusDescription}
            </Text>
        )
    }


    return (
        <Voting 
            onVote={onVote}
            currentVote={existingUserVote}
        />
    )
}