import { Text } from 'grommet';
import React from 'react';

interface Props {
    username: string;
}
export const ProfileNotFound: React.FC<Props> = ({username}) => (
    <>
        <Text>Woops, we can't find any DJ's called @{username}</Text>
    </>
)