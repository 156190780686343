import { Page, PageContent, Box } from 'grommet';
import 'lib/firebase';
import { AuthPage } from 'pages/auth';
import { OAuthPage } from 'pages/auth/oauth';
import { HomePage } from 'pages/home';
import { AccountPage } from 'pages/account';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ProfilePage } from 'pages/profile';
import { usePageSize } from 'hooks/ui';

function App() {

  const size = usePageSize();

  const content = (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/auth" element={<AuthPage />} />
        <Route path='/auth/provider/:providerName' element={<OAuthPage />} />
        <Route path='/account' element={<AccountPage />} />
        <Route path='/dj/:username' element={<ProfilePage />} />
      </Routes>
    </Router>
  )

  return (
    <Box align='center' pad={{top: 'small'}}>
      {content}
    </Box>
  )
}

export default App;
