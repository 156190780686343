import { ROUTES, Song } from 'api/youdj/requests';
import { User } from 'firebase/auth';
import { Box, Button, Spinner, Text, TextInput } from 'grommet';
import * as Icons from 'grommet-icons';
import { useAuth } from 'hooks/auth';
import { useOutsideClick } from 'hooks/dom';
import { apiClient } from 'lib/client';
import { SearchResultRow } from 'pages/show/search_result_row';
import React, { useEffect, useRef, useState } from 'react';

const search = (query: string, user: User) => {
    const client = apiClient(ROUTES.searchSongByQuery, user);
    return client.request({
        query
    });
}

export const SongSearch: React.FC = () => {

    const [query, setQuery] = useState('');
    const [timeout, setTimeoutState] = useState<NodeJS.Timeout>();
    const [isSearching, setIsSearching] = useState(false);
    const [results, setResults] = useState<Song[]>([]);
    const {user} = useAuth();
    const [hideResults, setHideResults] = useState(false);
    const searchRef = useRef<HTMLDivElement>(null);

    const clearResults = () => {
        setResults([]);
    }

    const resetSearch = () => {
        clearResults();
        setQuery('');
    }

    const toggleHideResults = () => {
        setHideResults(!hideResults);
    }
    
    useOutsideClick(searchRef, clearResults);

    const clearSavedTimeout = () => {
        if (timeout) {
            clearTimeout(timeout);
        }
    }
    
    useEffect(() => {
        if (!query || query.length < 3) {
            clearSavedTimeout();
            setResults([]);
            return;
        }
        clearSavedTimeout();
        setTimeoutState(setTimeout(() => {
            setIsSearching(true);
            search(query, user!).then(response => {
                setHideResults(false);
                setResults(response.results || []);
            }).finally(() => {
                setIsSearching(false);
            });
        }, 1000));
    }, [query])

    const showBody = isSearching || results.length > 0;

    return (
        <Box ref={searchRef}>
            <Box direction='row'>
                <TextInput 
                    placeholder='Request a song'
                    style={{fontWeight: '300'}}
                    icon={
                        <Icons.Search />
                    }
                    onChange={(event) => {
                        setQuery(event.target.value)
                    }}
                    value={query}
                />
                {query.length > 0 && (
                    <Button 
                        icon={<Icons.Close />}
                        onClick={resetSearch}
                    />
                )}
            </Box>
            {showBody && (
                <Box align='center' pad='small' round={{corner: 'bottom', size: 'small'}} elevation='small'>
                    {isSearching && <Spinner size='small' />}
                    {results.length > 0 && (
                        <>
                            {!hideResults && results.map(song => (
                                    <SearchResultRow song={song} key={song.id}/>
                                 ))
                            }
                            <Box>
                                {
                                    !isSearching && <Button onClick={toggleHideResults} label={hideResults ? 'Show Results' : 'Hide Results'}/>
                                }
                            </Box>
                        </>
                        
                    )}
                </Box>
            )}
        </Box>
    )
}