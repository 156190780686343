import { Box, Text, Image, Avatar, Button } from 'grommet';
import { useAuth } from 'hooks/auth';
import * as Icons from 'grommet-icons';
import React from 'react';
import { LoginViewProps } from 'pages/auth/types';
import { usePageSize } from 'hooks/ui';
import { Link } from 'react-router-dom';

export const InformLoggedIn: React.FC<LoginViewProps> = ({onSwitchView}) => {

    const pageSize = usePageSize();
    const isLargePage = pageSize === 'large';

    const {user} = useAuth();
    if (!user) {
        return <></>
    }

    return (
        <>
            <Box 
                direction='row' 
                round='small' 
                width={isLargePage ? '80%' : '100%'}
                pad='small'
                elevation='small'
                justify='between'
                align='center'
            >
                <Avatar src={user.photoURL || undefined}>
                    <Icons.User  />
                </Avatar>
                <Box direction='column' justify='center'>
                    <Text weight={500} textAlign='start'>{user.displayName}</Text>
                    {isLargePage && (
                        <Text weight={400} textAlign='start' size='small'>{user.email}</Text>
                    )}
                </Box>
                <Link to='/account'>
                    <Button 
                        label='View' 
                        icon={<Icons.FormNext />}
                    />
                </Link>
            </Box>
            <Box height='15px' />
            <Button 
                label='Sign into a different account' 
                icon={<Icons.FormPrevious />} 
                onClick={onSwitchView}
            />
        </>
    )
}