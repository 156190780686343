import { Avatar, Box, Grid, Heading, TextInput, Text, Spinner, Card, CardBody, CardHeader, CardFooter, Button } from 'grommet';
import { useAuth } from 'hooks/auth';
import React from 'react';
import * as Icons from 'grommet-icons';
import { Navigate } from 'react-router';
import { CompleteAccountView } from 'pages/account/complete';
import { UsernameSelection } from 'pages/account/username';

export const AccountPage: React.FC = () => {

    const {user, determined, username, isAnonymous} = useAuth();
    
    if (!determined) {
        return (
            <Box pad='large'>
                <Spinner size='medium'/>
            </Box>
        )
    }

    if (isAnonymous) {
        return <Navigate to='/auth' />
    }

    return (
        <Box 
            pad='medium' 
            align='center'
            direction='column'
            width='100%'
            >
            <Box
                justify='around'
                direction='column'
                align='center'
                width='100%'
                >
                <Avatar src={user.photoURL || undefined} size='xlarge'>
                    <Icons.User  />
                </Avatar>
                <Heading level='3'>
                    {user.displayName}
                </Heading>
                {username ? <CompleteAccountView /> : <UsernameSelection /> }
            </Box>
        </Box>
    )
}