import { useAuth } from 'hooks/auth';
import { useParams } from 'hooks/navigation';
import React, { useState } from 'react';
import {Text, Button, Spinner} from 'grommet'
import { setupNewShow } from 'lib/show/setup';

export const NoActiveShow: React.FC = () => {
    const auth = useAuth();
    const {username} = useParams();
    const [isStartingShow, setIsStartingShow] = useState(false);

    const isOwnProfile = auth.username === username;

    const onStartShow = async () => {
        setIsStartingShow(true);
        setupNewShow(auth.user!).finally(() => setIsStartingShow(false));
    }

    return (
        isOwnProfile ? (
            <>
                <Text weight='lighter' margin='small'>You don't have an active show</Text>
                <Button 
                    label='Start a show' 
                    margin='small'
                    onClick={onStartShow}
                    icon={isStartingShow ? (
                        <Spinner size='small' />
                    ) : (
                        <></>
                    )}
                />
            </>
        ) : (
            <Text weight='lighter'>@{username} does not  have an active show</Text>
        )
    )
}