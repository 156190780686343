import { User, getAuth, onAuthStateChanged, signInAnonymously, Unsubscribe } from 'firebase/auth';
import { ensureRegistered } from 'lib/profile/functions';
import { getUsername } from 'lib/profile/username';
import { AuthContext } from 'lib/auth/context/auth';
import React from 'react';
import { Auth, AuthAttr, AuthContext as ContextType, LoginType } from 'types/auth';

type Props = React.PropsWithChildren<{}>;
export const AuthProvider: React.FC<Props> = ({children}) => {

    const [user, setUser] = React.useState<User>();
    const [shouldLoadAnonAuth, setShouldLoadAnonAuth] = React.useState(false);
    const [error, setError] = React.useState('');
    const [username, setUsername] = React.useState('');
    const [loginType, setLoginType] = React.useState<LoginType>();
    const authSubscriber = React.useRef<Unsubscribe>();

    const setUserAndUpdateAuthStatus = React.useCallback(async (newUser?: User) => {

    }, []);

    const authAttrToUpdateFunc: Record<keyof AuthAttr | string, Function> = {
        error: setError,
        username: setUsername,
        loginType: setLoginType
    }

    // When the page loads for a logged out user, or a user logs out
    // then we need to retrieve an anonymous user object
    React.useEffect(() => {
        if (!shouldLoadAnonAuth) {
            return;
        }
        // There's no need to listen for completion of this promise
        // because the onAuthStateChanged subscriber will pick it up
        signInAnonymously(getAuth());
    }, [shouldLoadAnonAuth])

    /**
     * Setup the auth state listener to run on startup & every time
     * the auth state refreshes.
     */
    React.useEffect(() => {
        if (authSubscriber.current) {
            return;
        }
        const unsub = onAuthStateChanged(getAuth(),newUser => {
            // If the user has logged out, we temporarily
            // set defined to False so that the anonomous auth
            // can be processed
            if (!newUser) {
                setUser(undefined);
                setShouldLoadAnonAuth(true);
                return;
            }

            // If the newUser is anonymous (logged out), we don't
            // care about the username or if they're registered
            if (newUser.isAnonymous) {
                setShouldLoadAnonAuth(false);
                setUser(newUser);
                return;
            }

            const load = async () => {
                let fetchedUsername;
                try {
                    [fetchedUsername] = await Promise.all([
                        getUsername(newUser), 
                        ensureRegistered(newUser)
                    ]);
                } catch(err) {
                    console.error(err);
                }
                
                setUser(newUser);
                if (fetchedUsername) {
                    setUsername(fetchedUsername);
                }
            }
            load();
        })
        authSubscriber.current = unsub;
    }, [setUserAndUpdateAuthStatus]);

    const auth: Auth = user ? ({
        user,
        determined: true,
        username,
        loginType,
        error,
        isAnonymous: user.isAnonymous
    }) : ({
        user: undefined,
        determined: false,
        isAnonymous: true
    })

    const authContext: ContextType = {
        ...auth,
        update: (attr, val) => {
            const updater = authAttrToUpdateFunc[attr];
            if (updater) {
                updater(val);
            }
        }
    }

    return (
        <AuthContext.Provider value={authContext}>
            {children}
        </AuthContext.Provider>
    )
}