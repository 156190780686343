import React from 'react'
import {Text} from 'grommet';

interface ScoreProps {
    score: number;
}
export const Score: React.FC<ScoreProps> = ({score}) => {

    const color = score === 0 ? undefined : (
        score > 0 ? 'status-ok' : 'status-error'
    )
    return (
        <Text color={color} margin={{horizontal: 'medium', vertical: 'small'}}>
            {score}
        </Text>
    )
}