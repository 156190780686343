import React from 'react';

import {Box, Image, Heading, Card, CardHeader, CardBody, CardFooter, ResponsiveContext} from 'grommet';
import { usePageSize } from 'hooks/ui';
import Logo from 'assets/inverse_logo.png';
import { Login } from 'pages/auth/login';

type Props = React.PropsWithChildren<{}>;
export const AuthBox: React.FC<Props> = ({children}) => {
    const pageSize = usePageSize();

    const isSmallPage = pageSize === 'small';
    const direction = isSmallPage ? 'column' : 'row';

    const headerWidth = isSmallPage ? '100%' : '40%';
    const contentWidth = isSmallPage ? '100%' : '60%';
    const imageWidth = isSmallPage ? '20%' : '40%';

    return (
        <>
            <Box 
                margin='xlarge' 
                direction={direction}
                width={{width: '100%', max: '1000px'}}
                justify='between'
                round='medium'
                overflow='hidden'
                elevation='large'
               >
                <Box 
                    background='brand'
                    justify='center'
                    align='center'
                    pad='small'
                    width={headerWidth}
                    >
                    <Image src={Logo} width={imageWidth}/>
                </Box>
                <Box 
                    background='light-1'
                    direction='column'
                    gap='large'
                    align='center'
                    justify='center'
                    pad='large'
                    width={contentWidth}
                >
                    {children}
                </Box>
            </Box>
        </>
    )
}