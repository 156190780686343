import { User } from "firebase/auth";
import { getDatabase, ref, update } from "firebase/database";
import { Votes } from "lib/show/types";

const getRequestRef = (uid: string, showId: string, requestId: string) => {
    return ref(getDatabase(), `/shows/${uid}/${showId}/requests/${requestId}`);
}

export const voteForRequest = async (user: User, requestId: string, showId: string, dj: string, vote: number) => {
    const requestRef = getRequestRef(dj, showId, requestId);
    await update(requestRef, {
        [`votes/${user.uid}`]: vote
    })
}

export const getUsersVote = (votes: Votes, user: User) => {
    if (!votes) {
        return 0;
    }
    return votes[user.uid] || 0;
}