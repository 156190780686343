
export interface SearchRequest extends Record<string, unknown>  {
	query?: string;
	show_id?: string;
}

export interface MakeRequestResponse extends Record<string, unknown>  {
	success?: boolean;
}

export interface Song extends Record<string, unknown>  {
	name?: string;
	artist_name?: string;
	image_url?: string;
	id?: string;
}

export interface MakeRequestArg extends Record<string, unknown>  {
	track_id?: string;
	show_id?: string;
}

export interface SearchResponse extends Record<string, unknown>  {
	results?: Song[];
}

export const ROUTES = {
	searchSongByQuery: {
		path: '/youdj-searchSongByQuery',
		request: {} as SearchRequest,
		response: {} as SearchResponse,
	},
	makeRequest: {
		path: '/youdj-makeRequest',
		request: {} as MakeRequestArg,
		response: {} as MakeRequestResponse,
	},
}
