import { useAuth } from 'hooks/auth';
import { useActiveShow } from 'lib/show/context';
import React from 'react';
import {Menu, Text} from 'grommet';
import * as Icons from 'grommet-icons';
import { Score } from 'pages/show/score';
import { RequestStatus } from 'lib/show/types';
import { markRequestAsStatus } from 'lib/show/requests';

/**
 * Provides tools for the DJ to interact with any incoming
 * requests.
 */
interface RequestToolsProps {
    id: string;
    score: number;
}
export const DjRequestTools: React.FC<RequestToolsProps> = ({id, score}) => {

    const {user} = useAuth();
    const {dj, showId} = useActiveShow();

    // Request tools are only available to the DJ
    if (user?.uid !== dj) {
        return null;
    }

    const statusOnClick = (status: RequestStatus) => () => {
        return markRequestAsStatus(dj, showId, id, status);
    }

    const onBlock = (type: 'track' | 'artist') => {
        statusOnClick('removed')();

    }

    return (
        <>
            <Score 
                score={score}
            />
            <Menu 
                icon={
                    <Icons.CirclePlay
                        color='green'
                    />
                }
                items={[
                    {label: 'Playing now', onClick: statusOnClick('playing_now')},
                    {label: 'Played', onClick: statusOnClick('played')},
                    {label: 'In the queue', onClick: statusOnClick('queued')}
                ]}
            />
            <Menu 
                icon={
                    <Icons.Close
                        color='status-error'
                    />
                }
                items={[
                    {label: 'Remove', onClick: statusOnClick('removed')},
                    {label: 'Block track'},
                    {label: 'Block artist'}
                ]}
            />
        </>
    )
}