import { Box } from 'grommet';
import { useAuth } from 'hooks/auth';
import { useActiveShow } from 'lib/show/context';
import { Score } from 'pages/show/score';
import { DjRequestTools } from 'pages/show/tools/dj_tools';
import { FanTools } from 'pages/show/tools/fan_tools';
import { ToolsProps } from 'pages/show/tools/types';
import React from 'react';

export const RequestTools: React.FC<ToolsProps> = (props) => {

    const {isOwner, liveMode} = useActiveShow();

    const content: React.ReactNode[] = []
    if (isOwner && !liveMode) {
        content.push(
            <DjRequestTools
                {...props}
            />
        )
    } else if (!liveMode) {
        content.push(
            <FanTools 
                {...props}
            />
        )
    } else {
        content.push(
            <Score 
                score={props.score}
            />
        )
    }

    return (
        <Box direction='row' align='center'>
           {content}
        </Box>
    )
}
    