import { Song } from 'components/song';
import { Box, Button, Text } from 'grommet';
import * as Icons from 'grommet-icons';
import { useAuth } from 'hooks/auth';
import { useActiveShow } from 'lib/show/context';
import { computeVoteScore } from 'lib/show/sorting';
import { SongRequest } from 'lib/show/types';
import { getUsersVote, voteForRequest } from 'lib/show/vote';
import React from 'react';

interface VotingProps {
    onVote: (score: -1 | 1) => void;
    currentVote?: number;
}
export const Voting: React.FC<VotingProps> = ({onVote, currentVote}) => {

    return (
        <>
        <Button 
            icon={
                <Icons.Dislike color={currentVote === -1 ? 'status-error': undefined} />
            }
            onClick={() => onVote(-1)}
            color={currentVote === -1 ? 'brand': undefined}
            />
        <Button
            icon={
                <Icons.Like color={currentVote === 1 ? 'status-ok': undefined} />
            }
            onClick={() => onVote(1)}
            />
        </>
    )
}