import { getAuth, getRedirectResult, GoogleAuthProvider, signInWithRedirect } from "firebase/auth";
import { OAuthProviderName } from "types/auth";

/** LOGIN STARTERS */

const loginWithGoogle = () => {
    const provider = new GoogleAuthProvider();
    provider.addScope('email');
    provider.addScope('profile');
    signInWithRedirect(getAuth(), provider);
}

const providerDrivenLogins = {
    google: loginWithGoogle
}


export const loginWithProvider = (name: OAuthProviderName) => {
    const f = providerDrivenLogins[name];
    if (!f) {
        throw new Error(`Invalid auth provider: ${name}`);
    }

    f();
}

export const loginFromRedirectResponse = async (providerName: OAuthProviderName) => {
    const result = await getRedirectResult(getAuth());
    if (!result) {
        throw Error('No auth result found on redirect');
    }

    if (!result.user) {
        throw Error(`No authenticated user returned from auth process`);
    }

    return result.user
}