import { Spinner, Text } from 'grommet';
import { useAuth } from 'hooks/auth';
import { UserProfile } from 'lib/profile/types';
import { ShowContext } from 'lib/show/context';
import { useShowMetadata } from 'lib/show/state';
import { ShowContext as ContextType } from 'lib/show/types';
import { ActiveShow } from 'pages/show/active';
import { NoActiveShow } from 'pages/show/no_show';
import React, { useState } from 'react';
import { useParams } from 'react-router';

interface Props {
    toggleProfileHeader: (show: boolean) => void;
}
export const ShowController: React.FC<Props> = ({toggleProfileHeader}) => {

    const {username} = useParams();
    const auth = useAuth();
    const [liveMode, setLiveMode] = useState(false);

    const {isReady, showId, dj, title} = useShowMetadata(username!);
    if (!isReady) {
        return <Spinner size='small'/>
    }

    const contextVal: ContextType = {
        showId,
        dj,
        title,
        isOwner: Boolean(showId && auth.username === username),
        liveMode,
        toggleLiveMode: () => {
            const newLiveMode = !liveMode;
            // dont' show the header whenever live mode is active
            toggleProfileHeader(!newLiveMode)
            setLiveMode(newLiveMode);
        }
    }

    return (
        <ShowContext.Provider value={contextVal}>
            {showId ? (
                <ActiveShow />
            ) : (
                <NoActiveShow />
            )}
        </ShowContext.Provider>
    )
}