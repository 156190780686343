import React, { useEffect } from 'react';
import {Box, Text, Image, ThemeType} from 'grommet';
import {QRCodeCanvas} from 'qrcode.react';
import QRCodeStyling, {Options} from 'qr-code-styling';
import Logo from 'assets/logo_disk.png';
import { useTheme } from 'hooks/grommet';

const createOptions = (data: string, {global}: ThemeType): Options => ({
    width: 300,
    height: 300,
    type: 'svg',
    data,
    image: Logo,
    margin: 10,
    qrOptions: {
      typeNumber: 0,
      mode: 'Byte',
      errorCorrectionLevel: 'Q'
    },
    imageOptions: {
      hideBackgroundDots: true,
      imageSize: 0.5,
      margin: 10,
      crossOrigin: 'anonymous',
    },
    dotsOptions: {
      color: global?.colors?.brand as string,
      type: 'rounded'
    },
    backgroundOptions: {
      color: '#fff',
    },
    cornersSquareOptions: {
      color: global?.colors?.brand as string,
      type: 'extra-rounded',
    },
    cornersDotOptions: {
      color: '#222222',
      type: 'dot',
    }
});

interface LogoProps {
    data: string;
}
export const LogoQRCode: React.FC<LogoProps> = ({data}) => {

  const theme = useTheme();
  // @ts-ignore
  const [qrCode] = React.useState(new QRCodeStyling(createOptions(data, theme)))
  const ref = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
      qrCode.append(ref.current!);
  }, [])

  return (
      <div ref={ref}></div>
  )
}