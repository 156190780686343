import React from 'react';
import {Nav, Image, Heading as HeadingText, Box, Button} from 'grommet';
import * as Icons from 'grommet-icons';
import Logo from 'assets/logo_padded.png'
import { usePageSize } from 'hooks/ui';


export const Header: React.FC = () => {

    const isMobile = usePageSize() === 'small';

    return (
        <>
            <Nav 
                direction='row'
                align='center'
                justify='between'
                width='100%'
            >
                <Image 
                    src={Logo}
                    height='200px'
                    />
                {!isMobile && (
                    <Box direction='row' align='center'>
                        <HeadingText level='2' color='brand'>Take control of the music</HeadingText>
                    </Box>
                )}
            </Nav>
        </>
    )
}