
export interface SetUsernameRequest extends Record<string, unknown>  {
	username?: string;
}

export interface SetUsernameResponse extends Record<string, unknown>  {
	error?: string;
}

export interface Empty extends Record<string, unknown>  {
}

export const ROUTES = {
	setUsername: {
		path: '/youdj-setUsername',
		request: {} as SetUsernameRequest,
		response: {} as SetUsernameResponse,
	},
	ensureRegistered: {
		path: '/youdj-ensureRegistered',
		request: {} as Empty,
		response: {} as Empty,
	},
}
