import { AuthBox } from 'components/auth_box';
import React from 'react';
import {Spinner, Text} from 'grommet';

interface Props {
    text: string;
}
export const AuthSpinner: React.FC<Props> = ({text}) => {
    return (
        <AuthBox>
            <Text>{text}</Text>
            <Spinner size='medium' />
        </AuthBox>
    )
}