import { User } from "firebase/auth";
import { child, equalTo, get, getDatabase, orderByChild, query, ref } from "firebase/database";
import { getProfile } from "lib/profile/db";import { UserProfile } from "lib/profile/types";
;

export const getUsername = async (user: User) => {
    const username = await getProfile(user, 'username');
    return username;
}

export const getUserProfileFromUsername = async (username: string): Promise<UserProfile | undefined> => {
    const queryRef = ref(getDatabase(), 'profiles');
    const q = query(queryRef, orderByChild('username'), equalTo(username));
    const snap = await get(q);
    if (!snap.exists()) {
        return;
    }

    const result = snap.val();
    console.info(`Result from firebase: ${JSON.stringify(result)}`);
    const uid = Object.keys(result)[0];
    console.info(`Result from firebase UID: ${uid}`);
    return result[uid] as UserProfile;
}

export const getProfileFromUID = async (uid: string) => {
    const queryRef = ref(getDatabase(), 'profiles');
    const childRef = child(queryRef, uid);
    const snap = await get(childRef);
    return snap.val() as UserProfile;
}