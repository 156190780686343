import { ROUTES } from "api/youdj/requests";
import { User } from "firebase/auth";
import { set, update } from "firebase/database";
import { apiClient } from "lib/client";
import { getRequestStatusRef } from "lib/show/refs";
import { RequestStatus } from "lib/show/types";

export const requestSongForShow = (showId: string, trackId: string, user: User) => {
    const client = apiClient(ROUTES.makeRequest, user);
    return client.request({
        show_id: showId,
        track_id: trackId
    })
}

export const markRequestAsStatus = (dj: string, showId: string, requestId: string, status: RequestStatus) => {
    const statusRef = getRequestStatusRef(dj, showId, requestId);
    return set(statusRef, status);
}