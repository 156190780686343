import { Box, Button, Heading, TextInput } from 'grommet';
import * as Icons from 'grommet-icons';
import { useAuth } from 'hooks/auth';
import { usePageSize } from 'hooks/ui';
import { LoginViewProps } from 'pages/auth/types';
import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { OAuthProviderName } from 'types/auth';

export const Login: React.FC<LoginViewProps> = ({onSwitchView}) => {
    const pageSize = usePageSize();
    const isSmallPage = pageSize === 'small';

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const {update, user} = useAuth();
    const navigate = useNavigate();

    const createOAuthClick = (provider: OAuthProviderName) => () => {
        update('loginType', 'oauth');
        navigate(`/auth/provider/${provider}`)
    }

    return (
        <>
            <Heading level={4} style={{fontWeight: '500'}}>Sign in to take control of the music</Heading>
            <Box direction='row' width='100%' justify='around' pad={{bottom: isSmallPage ? 'large' : 'medium'}}>
                <Button label={isSmallPage ? '' : 'Apple'} icon={<Icons.Apple />}   />
                <Box height='10px' />
                <Button 
                    label={isSmallPage ? '' : 'Google'}     
                    icon={<Icons.Google />}
                    onClick={createOAuthClick('google')}
                    />
                <Box height='10px' />
                <Button label={isSmallPage ? '' : 'Facebook'} icon={<Icons.Facebook />}/>
            </Box>
            
            <TextInput
                placeholder="Email Address"
                value={email}
                onChange={event => setEmail(event.target.value)}
                type='email'
                icon={<Icons.Mail />}
            />
            <Box height='10px' />
            <TextInput
                placeholder="Password"
                value={password}
                onChange={event => setPassword(event.target.value)}
                type='password'
                icon={<Icons.Key />}
            />
            <Box height='10px' />
            <Box direction='row' width='100%' justify='around'>
                <Button label='Sign in' primary/>
            </Box>
            <Box pad='medium' direction='column'>
                <Button label="Create an account" secondary/>
                <Box height='10px' />
                <Button label='Reset Password'/>
                <Box height='10px' />
                {user && (
                    <Button label={`Sign in as ${user.displayName}`} onClick={onSwitchView}/>
                )}
            </Box>
        </>
    )
}