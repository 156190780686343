import { AuthProvider } from 'components/auth_provider';
import { Grommet } from 'grommet';
import React from 'react';
import theme from 'theme';

type Props = React.PropsWithChildren<{}>
export const Providers: React.FC<Props> = ({children}) => {

    const [mode, setMode] = React.useState<'light' | 'dark'>('light')

    return (    
        <>
            <Grommet theme={theme} themeMode={mode}>
                <AuthProvider>
                    {children}     
                </AuthProvider>
            </Grommet>
        </>
    )
}