import { ThemeType, defaultProps } from "grommet";

type ColorType = string | { dark?: string; light?: string } | undefined;;
interface ColorPalette {
    // color used on active hover state
    active?: ColorType;

    // shade of black
    black?: ColorType;

    // border color for all modes
    border?: ColorType;

    // the main brand color
    brand?: ColorType;

    // control color
    control?: ColorType;

    // the color to be used when element is in focus    
    focus?: ColorType;

    // the text color of the input placeholder
    placeholder?: ColorType;

    // color used on active state
    selected?: ColorType;

    // text color 
    text?: ColorType;

    // Color of icons
    icon?: ColorType;

    // shade of white
    white?: ColorType;
}

interface Colors extends ColorPalette{
    'accent-1'?: ColorType;
    'accent-2'?: ColorType;
    'accent-3'?: ColorType;
    'accent-4'?: ColorType;
    'background-back'?: ColorType;
    'background-contrast'?: ColorType;
    'background-front'?: ColorType;
    'neutral-1'?: ColorType;
    'neutral-2'?: ColorType;
    'neutral-3'?: ColorType;
    'neutral-4'?: ColorType;
    'dark-1'?: ColorType;
    'dark-2'?: ColorType;
    'dark-3'?: ColorType;
    'dark-4'?: ColorType;
    'dark-5'?: ColorType;
    'dark-6'?: ColorType;
    'light-1'?: ColorType;
    'light-2'?: ColorType;
    'light-3'?: ColorType;
    'light-4'?: ColorType;
    'light-5'?: ColorType;
    'light-6'?: ColorType;
    'status-critical'?: ColorType;
    'status-error'?: ColorType;
    'status-warning'?: ColorType;
    'status-ok'?: ColorType;
    'status-unknown'?: ColorType;
    'status-disabled'?: ColorType;
    'graph-0'?: ColorType;
    'graph-1'?: ColorType;
    'graph-2'?: ColorType;
    'graph-3'?: ColorType;
    'graph-4'?: ColorType;
    'graph-5'?: ColorType;
    [x: string]: ColorType;
  };


const textIconColor: ColorType = {
    light: '#000',
    dark: '#fff',
};

const BRAND = '#7400B8';
const HIGHLIGHT = '#4EA8DE'

export const palette: Colors = {
    ...defaultProps.theme.global?.colors,
    brand: BRAND,
    text: textIconColor,
    icon: textIconColor,
    active: HIGHLIGHT,
    selected: HIGHLIGHT,
    placeholder: {
        light: '#636564',
        dark: '#DBD1D1'
    },
    focus: BRAND
}