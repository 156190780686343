import { ROUTES } from 'api/youdj/auth';
import { Box, Button, Spinner, Text, TextInput } from 'grommet';
import { useAuth } from 'hooks/auth';
import { apiClient } from 'lib/client';
import React from 'react';

export const UsernameSelection: React.FC = () => {
    const [username, setUsername] = React.useState('');
    const [error, setError] = React.useState('');
    const {user, update} = useAuth();
    const [isLoading, setIsLoading] = React.useState(false);

    const isValidUsername = (value: string): boolean => {
        return !!value && value.length >= 3 && /[0-9a-zA-Z_]+/.test(value);
    }

    const isDisabled = () => {
        return Boolean(error || !isValidUsername(username));
    }

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.currentTarget.value;
        setUsername(value.toLowerCase());
        setError('');
    }

    const onClick = () => {
        const client = apiClient(ROUTES.setUsername, user);
        setIsLoading(true);
        client.request({username}).then(response => {
            if (response.error) {
                setError(response.error)
                return;
            }
            update('username', username);
        }).finally(() => {setIsLoading(false)});
    }

    const shouldShowError = error || username && !isValidUsername(username);

    return (
        <Box width='100%' elevation='small' round='small' pad='medium'>
            <Text>
                Choose a DJ tag
            </Text>
            <Box height='15px'/>
            <TextInput 
                placeholder='@username'
                width='80%'
                autoFocus={true}
                style={{fontWeight: 300}}
                maxLength={32}
                onChange={onChange}
                value={username}
            />
            {shouldShowError && <Text color='status-error'>{error || 'Username must be at least 3 letters long and contain only letters, numbers and underscores'}</Text>}
            <Box height='15px'/>
            <Button 
                label='Save' 
                disabled={isDisabled()} 
                onClick={onClick}
                icon={
                    isLoading ? <Spinner size='small'/> : <></>
                }
            />
        </Box>
    )
}