import { useSongRequests } from 'lib/show/context';
import React from 'react';
import FlipMove from 'react-flip-move';

import { RequestWithRef } from 'pages/show/request';

interface Props {
    top?: number;
}
export const RequestsList: React.FC<Props> = ({top})  => {
    const requests = useSongRequests();
    const topRequests = top ? requests.slice(0, top) : requests;
    return (
        <>
            {/* @ts-ignore */}
            <FlipMove>
                {topRequests.map(req => <RequestWithRef {...req} key={req.id}/>)}
            </FlipMove>
        </>
    )
};