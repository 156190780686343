import { User } from "firebase/auth";
import { getDatabase, ref, push, remove, update, set, child } from "firebase/database";
import { updateProfile } from "lib/profile/db";
import { Show } from "lib/show/types";
import { generateSlug } from "random-word-slugs";

export const getShowsRef = () => {
    return ref(getDatabase(), 'shows');
}

/**
 * At shows/registry/{showId} you will find the user id of the dj
 */
export const getShowRegistryRef = () => {
    return child(getShowsRef(), `registry`);
}

export const getShowRefForDj = (dj: string, id: string) => {
    return child(getShowsRef(), `${dj}/${id}`);
}

export const getDjShowsRef = (dj: string) => {
    return child(getShowsRef(), dj);
}

export const getRequestRef = (dj: string, show: string, request: string) => {
    return child(getShowRefForDj(dj, show), `requests/${request}`);
}

export const getRequestStatusRef = (dj: string, show: string, request: string) => {
    return child(getRequestRef(dj, show, request), 'status');
}