import React from 'react';

import {Box, Button, Heading} from 'grommet';
import { Header } from 'components/header';
import { Link } from 'react-router-dom';
import { usePageSize } from 'hooks/ui';

export const HomePage: React.FC = () => {

    const isMobile = usePageSize() === 'small';

    return (
        <Box 
            pad='large' 
            width='100%'
            direction='column'
            align='center'
        >
            <Header />
            <Box>
                <Heading level='1' textAlign='center'>The power to DJ your best</Heading>
            </Box>
            <Box direction='row' width={isMobile ? '100%' : '50%'} justify='around'>
                <Link to='/dj/ihm'>
                    <Button label='Find a live show'/>
                </Link>
                <Link to='/auth'>
                    <Button label='Sign in'/>
                </Link>
            </Box>
        </Box>
    )
}