import { User } from "firebase/auth";
import { getDatabase, ref, push, remove, update, set } from "firebase/database";
import { updateProfile } from "lib/profile/db";
import { getShowRegistryRef, getShowRefForDj, getShowsRef } from "lib/show/refs";
import { Show } from "lib/show/types";
import { generateSlug } from "random-word-slugs";

const generateRandomShowName = () => {
    return generateSlug(3, {format: 'title'})
}

const createShowObj = (user: User, id: string): Show => ({
    dj: user.uid,
    title: generateRandomShowName(),
    status: 'active', 
    requests: {},
    id
});

export const setupNewShow = async (user: User): Promise<Show> => {
    // Create the show ID by pushing to the registry
    const djRegistryRef = getShowRegistryRef();
    const registryRef = await push(djRegistryRef, user.uid);
    const showId = registryRef.key;
    if (!showId) {
        await remove(registryRef);
        throw new Error(`Failed to create show for ${user.uid}`);
    }

    // Create the show in /shows
    const show = createShowObj(user, showId);
    await set(getShowRefForDj(user.uid, showId), show);

    // Register the show Id with the user
    await updateProfile(user, 'activeShowId', showId);

    return show
}