
import axios, { AxiosRequestHeaders } from 'axios';
import {User} from 'firebase/auth';
import { getToken } from 'firebase/app-check';
import { appCheck } from 'lib/firebase';
import { isDev } from 'lib/env';

type BodyType = Record<string, unknown>;

interface RouteWithPath<R extends BodyType, Q extends BodyType> {
    path: string;
    request: R;
    response: Q;
}

interface Options {
    disableAppCheck?: boolean;
}

class ApiClient<R extends BodyType, Q extends BodyType> {
    
    path: string;
    user?: User;
    private options: Options;
    private token?: string;

    constructor(path: string, user?: User, options?: Options) {
        this.path = path;
        this.user = user;
        this.options = options || {};
    }

    get devUrl() {
        const url = new URL(window.location.href);
        url.port = '3005';
        return url.origin
    }

    get prodUrl() {
        return 'https://us-central1-magnify-software.cloudfunctions.net/'
    }

    get url() {
        const origin = isDev() ? this.devUrl : this.prodUrl;
        return `${origin}${this.path}`;
    }

    request = async (body: R): Promise<Q> => {
        const headers: AxiosRequestHeaders = {};

        if (this.user) {
            const token = await this.user.getIdToken();
            headers['Authorization']  = `Bearer ${token}`;
        }

        // TODO: RE-ENABLE APP CHECK
        /**if (!this.options.disableAppCheck) {
            const {token} = await getToken(appCheck);
            headers['X-Firebase-AppCheck'] = token;
        }*/

        const response = await axios.post(this.url, body, {headers});

        return response.data;
    }
}

export const apiClient = <R extends BodyType, Q extends BodyType>(route: RouteWithPath<R, Q>, user?: User) => {
    return new ApiClient<R, Q>(route.path, user);
}