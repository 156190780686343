import { Avatar, Heading, Text } from 'grommet';
import * as Icons from 'grommet-icons';
import { UserProfile } from 'lib/profile/types';
import React from 'react';


export const Header: React.FC<UserProfile> = ({photoUrl, displayName, username}) => (
    <>
        <Avatar src={photoUrl} size='xlarge'>
            <Icons.User  />
        </Avatar>
        <Heading level='3' margin={{bottom: 'small'}}>
            {displayName || `Woops, we can't find any DJ's called ${username}`} 
        </Heading>
        <Text margin={{bottom: 'small'}}>
            @{username}
        </Text>
    </>
)