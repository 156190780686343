

const isProdOrigin = (o: string) => {
    if (o.endsWith('.web.app')) {
        return true;
    }
    if (o.endsWith('youdj.me')) {
        return true;
    }
    return false;
}

export const isProd = () => {
    const url = new URL(window.location.href);
    return isProdOrigin(url.origin);
}

export const isDev = () => {
    return !isProd();
}